exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-drawings-tsx": () => import("./../../../src/pages/drawings.tsx" /* webpackChunkName: "component---src-pages-drawings-tsx" */),
  "component---src-pages-feelings-tsx": () => import("./../../../src/pages/feelings.tsx" /* webpackChunkName: "component---src-pages-feelings-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-paintings-tsx": () => import("./../../../src/pages/paintings.tsx" /* webpackChunkName: "component---src-pages-paintings-tsx" */),
  "component---src-pages-portraits-tsx": () => import("./../../../src/pages/portraits.tsx" /* webpackChunkName: "component---src-pages-portraits-tsx" */)
}

